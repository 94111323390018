import { useState } from "react";
import { useTranslation } from "react-i18next";

import "./Book.scss";

const Book = () => {
  const { t } = useTranslation();

  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const handleBookClick = () => {
    if (isChecked) {
      window.location.href = 'https://bit.ly/sndphtstd';
    }
  };

  return (
    <main className="book">
      <h1 className="book__title">{t("rules")}</h1>
      <ul className="book__rules">
        <li>{t("rule1")}</li>
        <li>{t("rule2")}</li>
        <li>{t("rule3")}</li>
        <li>{t("rule4")}</li>
      </ul>
      <div className="book__bottom">
        <label  htmlFor="checkbox">
          <input
            id="checkbox"
            className="book__bottom__checkbox"
            type="checkbox"
            checked={isChecked}
            onChange={handleCheckboxChange}
          />
          <span>{t("bookAccept")}</span>
        </label>
        <button onClick={handleBookClick} disabled={!isChecked} className="book__bottom__bookBtn">{t("bookBtn")}</button>
      </div>
    </main>
  );
};

export default Book;
