import { Route, Routes, Navigate } from "react-router-dom";
import Header from "./components/Header/Header";

import "./App.scss";
import Book from "./components/Book/Book";

function App() {
  return (
    <>
      <Header />
      <Routes>
        <Route path="/" element={<Navigate to="/book" />} />
        <Route path="/book" element={<Book />} />
      </Routes>
    </>
  );
}

export default App;
