// src/i18n.ts
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import translationsEN from "./locales/en.json";
import translationsHY from "./locales/hy.json";
import translationsRU from "./locales/ru.json";

const resources = {
  en: {
    translation: translationsEN,
  },
  hy: {
    translation: translationsHY,
  },
  ru: {
    translation: translationsRU,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "hy",
  fallbackLng: "hy",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
