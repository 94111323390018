import "./Header.scss";

import logoSrc from "./../../assets/logo.png";
import { useTranslation } from "react-i18next";
import { changeLanguage } from "i18next";
import classNames from "classnames";

const languages = [
  {
    name: "Հայ",
    code: "hy",
  },
  {
    name: "Рус",
    code: "ru",
  },
  {
    name: "Eng",
    code: "en",
  },
];

const Header = () => {
  const { i18n } = useTranslation();

  const handleLanguageClick = (code: string) => {
    changeLanguage(code);
  };

  return (
    <header className="header">
      <img className="header__logo" src={logoSrc} alt="logo" />
      <div className="languages-container">
        {languages.map((language, index) => (
          <span
            onClick={() => handleLanguageClick(language.code)}
            key={language.code}
            className={classNames({
              active: language.code === i18n.language,
              borders: index === 1
            })}
          >
            {language.name}
          </span>
        ))}
      </div>
    </header>
  );
};

export default Header;
